require('owl.carousel');
import './scss/style.scss';
require('bootstrap');
require('gasparesganga-jquery-loading-overlay');
require('bootstrap-datepicker');
require('email-autocomplete');
require('mailcheck');
require('croppie');
require('exif-js');
require('datatables.net');

let Onfido = null;
if ($('#onfido-mount').length > 0) {
    Onfido = require('onfido-sdk-ui');
}
var onfidoIdentity;

if ($('#onfido-mount').length > 0) {
    try {
        var onfidoSteps = [
            {
                type: 'welcome',
                options: {
                    title: 'Verify your identity',
                    descriptions: ['We need to verify your identity before you can start claiming your equine passports. It will only take a couple of minutes.']
                }
            }
        ];
        if (!onfidoDocumentCheckSuccess) {
            onfidoSteps.push('document');
        }
        if (!onfidoFacialCheckSuccess) {
            onfidoSteps.push('face');
        }

        onfidoIdentity = Onfido.init({
            // the JWT token that you generated earlier on
            token: onfidoSdkToken,
            // id of the element you want to mount the component on
            containerId: 'onfido-mount',
            steps: onfidoSteps,
            // Language overrides
            language: {
                locale: "en",
                phrases: {
                    "capture.driving_licence.front.title": "Front of driver's licence",
                    "capture.driving_licence.front.instructions": "Upload front of licence from your computer",
                    "capture.driving_licence.front.webcam": "Position the front of licence in the frame (it will be automatically detected)",
                    "capture.driving_licence.back.title": "Back of driver's licence",
                    "capture.driving_licence.back.instructions": "Upload back of licence from your computer",
                    "capture.driving_licence.back.webcam": "Position the back of licence in the frame (it will be automatically detected)",
                    "confirm.driving_licence.message": "Make sure your licence details are clear to read, with no blur or glare",
                    "driving_licence": "Driver's Licence",
                    "short_driving_licence": "licence"
                }
            },
            onComplete: function (data) {
                console.log("everything is complete")
                $.ajax({
                    url: "/dashboard/verify/documentComplete",
                    type: "POST",
                    success: function( data ) {
                        if (data.detail) {
                            alert(data.detail)
                        } else {
                            location.href='/dashboard/verify/success';
                        }
                    },
                    dataType: "json"
                })
                // You can now trigger your backend to start a new check
                // `data.face.variant` will return the variant used for the face step
                // this can be used to perform a facial similarity check on the applicant
            }
        });

    } catch (err) {
        console.log(err.message);
    }

}

var registration = (function() {
    "use strict";
    var bindEvents = function() {
        $(".inputs").keyup(function () {
            if ($(this).val().length == $(this).attr('maxlength')) {
                var next = $('#' + $(this).data('tab-target'));
                if (next) {
                    next.focus();
                    next.select();
                } else {
                    $(this).blur();
                }
            }
        });
        $("#email").on('load', function() {
            emailautocomplete({
                suggClass: "eac-suggestion"
            });
        });
        $('#email').on('blur', function(event) {
            $(this).mailcheck({
                suggested: function(element, suggestion) {
                    // callback code
                    $('#suggestion').html('Did you mean <a href="#" id="suggestionText">' + suggestion.full + '</a>?');
                },
                empty: function(element) {
                    // callback code
                    $('#suggestion').html('');
                }
            });
        });
        $('body').on('click', '#suggestionText', function(e) {
            e.preventDefault();
            $('#email').val($(this).html());
            $('#suggestion').html('');
        });
        $('button[type=submit]').on('click', function(e){
            $('#dob').val($('#dob-year').val() + '-' + $('#dob-month').val() + '-' + $('#dob-day').val());
        });
    };
    var init = function() {
        bindEvents();
    };
    return {
        init: init
    }
})();

var dashboard = (function() {
    "use strict";
    var init = function() {
        bindEvents();
    };

    var bindEvents = function() {
        $('.owl-carousel').owlCarousel({
            stagePadding:10,
            margin:40,
            items:1,
            lazyLoad: true,
            nav:true,
            responsive:{
                0:{
                    items:1
                },
                600:{
                    items:1
                },
                1000:{
                    items:1
                },
                1200:{
                    items:2
                },
                1400:{
                    items:2

                },
                1600:{
                    items:2
                },
                1800:{
                    items:2
                }
            }
        });
    };
    return {
        init: init
    }
})();

var submitButtonAnimation = (function() {
    "use strict";
    var bindEvents = function() {
        $("form button[type=submit]").click(function() {
            $("button[type=submit]", $(this).parents("form")).removeAttr("clicked");
            $(this).attr("clicked", "true");
        });
        $('button[type=submit]').closest('form').submit(function(e){
            var clicked = $(this).find('button[type=submit][clicked=true]');
            $(clicked).html('Loading...');
        });
    };
    var init = function() {
        bindEvents();
    };
    return {
        init: init
    }
})();

var passwordStrength = (function() {
    "use strict";

    var options = {
        minimumGroups: 3,
        minimumLength: 8,
        minimumLower: 1,
        minimumNumbers: 1,
        minimumSymbols: 1,
        minimumUpper: 1
    };


    var bindEvents = function() {
        $('#password, #password-verify').on('keyup', checkPassword);
    };

    var checkPassword = function (e) {
        var password = $(this).val();
        if (calculateStrength(password)) {
            $('.password-bad').hide();
            $('.password-good').show();
        } else {
            $('.password-bad').show();
            $('.password-good').hide();
        }
    };

    var calculateStrength = function(password) {
        var inGroups = 0;
        if (password.length > options.minimumLength) {
            $('#password-length').addClass('complete');
        } else {
            $('#password-length').removeClass('complete');
        }
        if (password.match(/([0-9])/g)) {
            $('#password-numbers').addClass('complete');
            inGroups += 1;
        } else {
            $('#password-numbers').removeClass('complete');
        }
        if (password.match(/([A-Z])/g)) {
            $('#password-uppercase').addClass('complete');
            inGroups += 1;
        } else {
            $('#password-uppercase').removeClass('complete');
        }
        if (password.match(/([a-z])/g)) {
            $('#password-lowercase').addClass('complete');
            inGroups += 1;
        } else {
            $('#password-lowercase').removeClass('complete');
        }
        if (password.match(/([!,@,#,$,%,^,&,*,?,_,~])/g)) {
            $('#password-special').addClass('complete');
            inGroups += 1;
        } else {
            $('#password-special').removeClass('complete');
        }
        if (inGroups >= options.minimumGroups) {
            if (password.length < options.minimumLength) {
                return false;
            }
            return true;
        } else {
            return false;
        }

    };
    var init = function() {
        bindEvents();
    };
    return {
        init: init
    }
})();

var accountTabs = (function () {
    "use strict";

    var bindEvents = function() {
        $('#myModal').on('show.bs.modal', function () {
            $('.documents').on('click', doNotificationTab);
        });
    };

    var doNotificationTab = function(e) {
        e.preventDefault();
        $('.account-tabs li a[href="#documents"]').click();
        $('#myModal').modal('hide');
    };

    var init = function() {
        var tabId = window.location.hash;
        if (!tabId) {
            tabId = '#account';
        }
        var myTab = $('.account-tabs a[href=\\' + tabId + ']');
        if (myTab != null) {
            myTab.tab('show');
        }
        bindEvents();
    };
    return {
        init: init
    }

})();

var addressLookup = (function () {
    "use strict";

    var bindEvents = function() {
        $('#findAddress').on('click', doLookup);
        $('#manualAddress').on('click', manualAddress);
        $('#postcodeLookup').on('click', postcodeLookup);
        $('#select-box-address').change(toggleAddAddressButton);
        $('input[name=postcode-manual]').keyup(validateAddress);
        $('textarea[name=address-manual]').keyup(validateAddress);

        $('.findAddress').on('click', doAddressLookup);
        $('.manualAddress').on('click', manualAddressEntry);
        $('.postcodeLookup').on('click', postcodeAddressLookup);
    };

    var toggleAddAddressButton = function(e) {
        if($(this).prop('selectedIndex')) {
            $('#btn-add-address').removeAttr('disabled');
        }  else {
            $('#btn-add-address').attr("disabled", "disabled");
        }
    }

    var validateAddress = function(e) {
        if($('textarea[name=address-manual]').val() != '' && $('input[name=postcode-manual]').val() != '') {
            $('#btn-add-address').removeAttr('disabled');
        } else {
            $('#btn-add-address').attr("disabled", "disabled");
        }
    }

    var postcodeLookup = function(e) {
        e.preventDefault();
        $('textarea[name=address-manual]').val('');
        $('input[name=postcode-manual]').val('');
        $('.address-lookup').slideDown();
        $('.address-manual').slideUp();
        $('#btn-add-address').attr("disabled", "disabled");
    };

    var postcodeAddressLookup = function(e) {
        e.preventDefault();
        var id = $(this).attr('rel');
        $('#address-manual-'+id).val('');
        $('#postcode-manual-'+id).val('');
        $('#addressLoader-'+id).slideDown();
        $('#address-manual-div-'+id).slideUp();
        $('#btn-add-address').attr("disabled", "disabled");
    };


    var manualAddress = function(e) {
        e.preventDefault();
        $('.address-not-found').slideUp();
        $('input[name=postcode]').val('');
        $('#select-box-address')
            .find('option')
            .remove();
        $('.address-list-select').slideUp();
        $('.address-lookup').slideUp();
        $('.address-manual').slideDown();
        $('#btn-add-address').attr("disabled", "disabled");
    };

    var manualAddressEntry = function(e) {
        e.preventDefault();
        var id = $(this).attr('rel');
        $('#address-not-found-'+id).fadeOut();
        $('#postcode-'+id).val('');
        $('#select-box-address-'+id)
            .find('option')
            .remove();
        $('#address-list-select-'+id).slideUp();
        $('#addressLoader-'+id).slideUp();
        $('#address-manual-div-'+id).slideDown();
        $('#btn-add-address').attr("disabled", "disabled");
    };

    var doAddressLookup = function(e) {
        e.preventDefault();
        var id = $(this).data().id;
        $('#address-not-found-'+id).fadeOut();

        var postcode = $('#postcode-'+id).val().replace(/ +/g, '');
        var country = $('#select-box-country-'+id).val().replace(/ +/g, '');
        $(this).closest('div.input-group').removeClass('form-group-error');
        if (!postcode) {
            $(this).closest('div.input-group').addClass('form-group-error');
        } else {
            $('#addressLoader-'+id).LoadingOverlay('show', {image : "",fontawesome : "fa fa-circle-notch fa-spin", fontawesomeColor : "#006760"});
            $('#select-box-address-'+id)
                .find('option')
                .remove();

            var api = {
                endpoint: '/ajax/address/' + postcode + '/' + country
            };
            $.getJSON(api.endpoint)
                .then(function(response){
                    if (response.addresses && response.count > 0) {
                        $('#select-box-address-'+id).append($('<option>', {
                            value: '',
                            text: 'Please Select'
                        }));
                        $.each(response.addresses, function (index, value) {
                            $('#select-box-address-'+id).append($('<option>', {
                                value: index,
                                text: index
                            }));
                        });
                        $('#addressLoader-'+id).LoadingOverlay('hide');
                        $('#address-list-select-'+id).fadeIn();
                    } else {
                        $('#address-list-select-'+id).hide();
                        $('#addressLoader-'+id).LoadingOverlay('hide');
                        $(this).closest('div.form-control').addClass('form-group-error');
                        $('#address-not-found-'+id).fadeIn();
                    }
                })
                .catch(handleError);
        }
    }

    var doLookup = function(e) {
        e.preventDefault();
        $('.address-not-found').fadeOut();
        var postcode = $(this).closest('form').find('input[name=postcode]').val().replace(/ +/g, '');
        var country = $(this).closest('form').find('select[name=country]').val().replace(/ +/g, '');
        $(this).closest('div.input-group').removeClass('form-group-error');
        if (!postcode) {
            $(this).closest('div.input-group').addClass('form-group-error');
        } else {
            $('#addressLoader').LoadingOverlay('show', {image : "",fontawesome : "fa fa-circle-notch fa-spin", fontawesomeColor : "#006760"});
            $('#select-box-address')
                .find('option')
                .remove();

            var api = {
                endpoint: '/ajax/address/' + postcode + '/' + country
            };
            $.getJSON(api.endpoint)
                .then(renderAddresses)
                .catch(handleError);
        }
    };

    var renderAddresses = function(response) {

        if (response.addresses && response.count > 0) {
            $('#select-box-address').append($('<option>', {
                value: '',
                text: 'Please Select'
            }));
            $.each(response.addresses, function (index, value) {
                $('#select-box-address').append($('<option>', {
                    value: index,
                    text: index
                }));
            });
            $('#addressLoader').LoadingOverlay('hide');
            $('.address-list-select').fadeIn();
        } else {
            $('.address-list-select').hide();
            $('#addressLoader').LoadingOverlay('hide');
            $(this).closest('div.form-control').addClass('form-group-error');
            $('.address-not-found').fadeIn();
        }
    };

    // handle errors
    function handleError(err) {
        $('.address-list-select').hide();
        $('#addressLoader').LoadingOverlay('hide');
        $(this).closest('div.form-control').addClass('form-group-error');
        $('.address-not-found').fadeIn();
    }

    var init = function() {
        $('.address-manual').hide();
        $('#btn-add-address').attr("disabled", "disabled");
        bindEvents();
    };
    return {
        init: init
    }
})();

var showHide = (function () {
    "use strict";

    var bindEvents = function() {
        $('input[data-expand-target]').on('click', function (e) {
            $('.expand-target[data-expand-group=' + $(this).data('expand-group') + ']').slideUp();
            $('*[data-expand-name=' + $(this).data('expand-target') + ']').slideToggle();

        });
        $('a[data-expand-target]').on('click', function (e) {
            e.preventDefault();
            $('.expand-target[data-expand-group=' + $(this).data('expand-group') + ']').slideUp();
            $('*[data-expand-name=' + $(this).data('expand-target') + ']').slideToggle();
        });
    };

    var init = function() {
        $('.expand-target').slideUp();
        bindEvents();
    };

    return {
        init: init
    }
})();

var addMicrochip = (function() {
    "use strict";
    var bindEvents = function() {
        $('#addMicrochip').on('click', function(e) {
            e.preventDefault();
            var form = $('#addMicrochipForm').data('add-microchip');
            $('#microchip-list').append(form);
        });
        $('body').on('click', '.delete-microchip', function(e) {
            $(this).closest('.form-group').slideUp().remove();
        });

        var theParent = document.querySelector("#microchip-list");

        if(theParent){
            theParent.addEventListener("input", function (e) {
                if (e.target !== e.currentTarget) {
                    var microchipNumber = e.target;
                    if (microchipNumber.validity.patternMismatch) {
                        microchipNumber.setCustomValidity('Please enter an alphanumeric value of 9 to 25 characters in length');
                    } else {
                        microchipNumber.setCustomValidity('');
                    }
                }
            });
        }
    };

    var init = function() {
        bindEvents();
    };

    return {
        init: init
    }
})();

var passwordBox = (function() {
    "use strict";
    var bindEvents = function() {
        $('.password-toggle').click(function(e){
            e.preventDefault();
            var passwordBox = $(this).closest('div').find('input.password-input');
            var value = passwordBox[0].value;
            if (passwordBox[0].type == "password") {
                $(this).closest('div').find('.show-password').hide();
                $(this).closest('div').find('.hide-password').show();
                passwordBox[0].type = "text";
            } else {
                $(this).closest('div').find('.show-password').show();
                $(this).closest('div').find('.hide-password').hide();
                passwordBox[0].type = "password";
            }
            passwordBox[0].value = value;

        });
    };

    var init = function() {
        bindEvents();
    };

    return {
        init: init
    }
})();

var formConfirm = (function() {
    "use strict";
    var bindEvents = function() {
        $('#confirm').on('click', function(e) {
            if ($(this).is(':checked')) {
                $('#submit').removeAttr('disabled');
            } else {
                $('#submit').attr("disabled", "disabled");
            }
        });
    };

    var init = function() {
        $('#submit').attr("disabled", "disabled");
        bindEvents();
    };

    return {
        init: init
    }
})();

var modalControl = (function() {
    "use strict";
    var init = function() {
        $('.coming-soon-modal').on('click', function(e) {
            e.preventDefault();
            $.get('/ajax/modal/coming-soon', displayModal);
        });
        $('a[data-modal-target]').on('click', function(e) {
            var modalTarget = $(this).data('modal-target');
            if (modalTarget) {
                $.get('/ajax/modal/' + modalTarget, displayModal);
            }
        });
        $('a.modal-page').on('click', function(e) {
            e.preventDefault();
            var modalPage = $(this).attr('href');
            if (modalPage) {
                $.get(modalPage, displayModal);
            }
        });
        var modalTarget = $('#modalTarget').data('modal-target');
        if (modalTarget) {
            $.get('/ajax/modal/' + modalTarget, displayModal);
        }

        $('#myModal').on('hidden.bs.modal', function () {
            if($('#myModal #sendPostcard').length) {
                location.href='/dashboard/verify/send-postcard';
            }
            if($('#myModal #checkAddress').length) {
                location.href='/dashboard/address/check';
            }
        });
    };

    var displayModal = function (data, status) {
        if (data.html) {
            $('#myModal .modal-body').html(data.html);
        } else {
            $('#myModal .modal-body').html(data);
        }
        $('#myModal').modal('show');
    };

    return {
        init: init
    }
})();

var emailVerifiedPoll = (function() {
    "use strict";
    var bindEvents = function() {
        $('#email-verification-pending').each(pollServer);
    };
    var pollServer = function() {
        setTimeout(function() {
            $.ajax({
                url: "/ajax/user/email-verification-status",
                type: "GET",
                success: checkStatus,
                dataType: "json",
                complete: pollServer,
                timeout: 2000
            })
        }, 5000);
    };
    var checkStatus = function(data) {
        if (data.status) {
            location.href='/user/refresh';
        }
    };
    var init = function() {
        bindEvents();
    };
    return {
        init: init
    }
})();

var identityVerifiedPoll = (function() {
    "use strict";
    var bindEvents = function() {
        console.log('bindEvents');
        $('#onfido-identity').each(pollServer);
    };
    var pollServer = function() {
        console.log('pollServer');
        setTimeout(function() {
            $.ajax({
                url: "/ajax/user/onfido-verification-status",
                type: "GET",
                success: checkStatus,
                dataType: "json",
                complete: pollServer,
                timeout: 2000
            })
        }, 5000);
    };
    var checkStatus = function(data) {
        console.log('checkStatus');
        console.log(data);
        // if (data.identitystatus && $('#onfido-identity-complete').is(':visible') == false) {
        //     location.href='/dashboard/getstarted';
        // }
        if (data.onfido_application_status_code == 'completed-failed') {
            location.href='/dashboard/getstarted';
        }
        if (data.onfido_application_status_code == 'completed-deleted') {
            location.href='/dashboard/verify/identity-success';
        }

    };
    var init = function() {
        console.log('init...');
        bindEvents();
    };
    return {
        init: init
    }
})();

var locationType = (function() {
    "use strict";
    var bindEvents = function() {
        $('select[name=keeperType]').on('change', updateLabel);
    };
    var updateLabel = function() {
        $('#keeper-name-group').slideDown();
        $('label.keeperName').html($('select[name=keeperType] :selected').text() + ' Name');
    };
    var init = function() {
        $('#keeper-name-group').slideUp();
        bindEvents()
    };
    return {
        init: init
    }
})();

var locationChange = (function() {
    "use strict";
    var bindEvents = function() {
        $('.keeperType').on('change', updateKeeperLabel);
    };
    var updateKeeperLabel = function() {
        $('#'+$(this).attr('id')+'-name-group').slideDown();
        $('#'+$(this).attr('id')+'-name').html($('#'+$(this).attr('id')+' :selected').text() + ' Name');
    };
    var init = function() {
        bindEvents()
    };
    return {
        init: init
    }
})();

var btnLocationSubmit = (function() {
    "use strict";
    var bindEvents = function() {
        $('#btnLocationSubmit').on('click', saveLocationData);
    };

    var saveLocationData= function() {
        var equineIds = $('#equineIds').val();
        var equines = [];
        equines = equineIds.split(",");
        var error = [];
        $('#locationAlert').hide();
        $.each(equines, function( index, value ) {

            var postcode = $('#postcode-'+value).val();
            var postcodeManual = $('#postcode-manual-'+value).val();

            var keeperType = $('#keeper-type-'+value).val();
            var keeperName = $('#keeper-name-'+value).val();
            var addressManual = $('#address-manual-'+value).val();
            var selectAddress = $('#select-box-address-'+value).val();

            if(postcode == '' && postcodeManual == '') {
                if(error.indexOf(value) == -1){
                    error.push(value);
                }
            }

            if((addressManual == '' || addressManual == null) && (selectAddress == '' || selectAddress == null)) {
                if(error.indexOf(value) == -1){
                    error.push(value);
                }
            }

            if(keeperType == '' || keeperType == null) {
                if(error.indexOf(value) == -1){
                    error.push(value);
                }
            }

            if(keeperName == '') {
                if(error.indexOf(value) == -1){
                    error.push(value);
                }
            }
            if(error.indexOf(value) > -1){
                $('#location-added-'+value).hide();
                $('#location-added-error-'+value).show();
                $('#location-arrow-'+value).hide();
            } else {
                $('#location-added-'+value).show();
                $('#location-added-error-'+value).hide();
                $('#location-arrow-'+value).hide();
            }
        });

        if(error.length <= 0) {
            $('#frmLocation').submit();
        } else {
            $('#locationAlert').show();
        }
    };

    var init = function() {
        bindEvents()
    };

    return {
        init: init
    }

})();

var saveLocation = (function() {
    "use strict";
    var bindEvents = function() {
        $('.save-location').on('click', saveLocationDeatils);
    };
    var saveLocationDeatils= function() {
        var id = $(this).attr('rel');
        var error = false;
        $(".form-group-error").removeClass("form-group-error");
       $('#locationAlert').hide();
       var postcode = $('#postcode-'+id).val();
       var postcodeManual = $('#postcode-manual-'+id).val();

       var keeperType = $('#keeper-type-'+id).val();
       var keeperName = $('#keeper-name-'+id).val();
       var addressManual = $('#address-manual-'+id).val();
       var selectAddress = $('#select-box-address-'+id).val();

       if(postcode == '' && postcodeManual == '') {
           $('#postcode-'+id).closest('.form-group').addClass('form-group-error');
           $('#address-manual-'+id).parent('.form-group').addClass('form-group-error');
           $('#postcode-manual-'+id).closest('.form-group').addClass('form-group-error');
           error = true;
       }

       if((addressManual == '' || addressManual == null) && (selectAddress == '' || selectAddress == null)) {
           $('#postcode-'+id).closest('.form-group').addClass('form-group-error');
           $('#address-manual-'+id).parent('.form-group').addClass('form-group-error');
           $('#postcode-manual-'+id).closest('.form-group').addClass('form-group-error');
           error = true;
       }

       if(keeperType == '' || keeperType == null) {
           $('#keeper-type-'+id).closest('.form-group').addClass('form-group-error');
           error = true;
       }

       if(keeperName == '') {
           $('#keeper-name-'+id).closest('.form-group').addClass('form-group-error');
           error = true;
       }

       if(error === false) {
           $('#location-added-'+id).show();
           $('#location-added-error-'+id).hide();
           $('#location-arrow-'+id).hide();
           var panelId = $('#collapse-'+id).data().id;
           panelId = parseInt(panelId) + 1;
           if( $('.location-toggle-'+panelId).length )
            {
                 $('.location-toggle-'+panelId).trigger('click');
            } else {
                panelId = $('#collapse-'+id).data().id;
                $('.location-toggle-'+panelId).trigger('click');
            }
       } else {
           $('#location-added-'+id).hide();
           $('#location-added-error-'+id).show();
           $('#locationAlert').show();
           $('#location-arrow-'+id).hide();
       }


    };
    var init = function() {
        bindEvents()
    };
    return {
        init: init
    }
})();

var vaccinations = (function() {
    "use strict";
    var buildDate = function() {
        var date = new Date();
        var code = $(this).data('vaccination-code');
        var day = $('#' + code + '-day').val();
        var month = $('#' + code + '-month').val();
        var year = $('#' + code + '-year').val();
        var inputDate = new Date(year + '/' + month + '/' + day);
        if (inputDate.getTime() > date.getTime()) {
            $(this).closest('div.form-date').parent().parent().addClass('form-group-error');
            //$('#submitVaccinations').attr('disabled', 'disabled');
        } else {
            $('#' + code + '-date').val(day + '/' + month + '/' + year);
            $('#' + code + '-datepicker').datepicker('update', inputDate);
            $(this).closest('div.form-date').parent().parent().removeClass('form-group-error');
            //$('#submitVaccinations').removeAttr('disabled');
        }
    };
    var bindEvents = function() {
        $('.vaccination-year').on('change', buildDate);
        $('.vaccination-month').on('change', buildDate);
        $('.vaccination-day').on('change', buildDate);
        $('.vaccination-done').each(function() {
            $(this).on('click', closeDate);
        });
        $('.vaccination-change').each(function() {
            $(this).on('click', openDate);
        });
        $('.vaccination-option').each(function() {
            $(this).change(function() {
                var code = $(this).data('vaccination-code');

                if (this.checked) {
                    $('[data-expand-name=vaccination-' + code + ']').slideDown();
                } else {
                    $('[data-expand-name=vaccination-' + code + ']').slideUp();
                    $('#' + code + '-day').val('');
                    $('#' + code + '-month').val('');
                    $('#' + code + '-year').val('');
                    $('#vaccination-' + code + '-date').html('');
                    $('#vaccination-' + code + '-change-group').slideUp();
                    $(this).parent().parent().find('.form-group').removeClass('form-group-error');
                }
            });
        });
    };
    var openDate = function() {
        var code = $(this).data('vaccination-code');
        $('[data-expand-name=vaccination-' + code + ']').slideDown();
        $('#vaccination-' + code + '-date').html('');
        $('#vaccination-' + code + 'change-group').slideUp();
    };
    var closeDate = function() {
        var code = $(this).data('vaccination-code');
        $('[data-expand-name=vaccination-' + code + ']').slideUp();
        var day = $('#' + code + '-day').val();
        var month = $('#' + code + '-month').val();
        var year = $('#' + code + '-year').val();
        $('#vaccination-' + code + '-date').html(day + '/' + month + '/' + year);
        $('#vaccination-' + code + '-change-group').slideDown();
    };
    var init = function() {
        $('.vaccination-change-group').slideUp();
        bindEvents()
    };
    return {
        init: init
    }
})();

var insurance = (function() {
    "use strict";
    var bindEvents = function() {
        $('#show-provider-custom').on('click', function(e){
            e.preventDefault();
            $('#provider_list').prop('selectedIndex',0);
            $('.show-provider-list').slideDown();
            $('.show-provider-custom').slideUp();
            $('#provider_list').slideUp();
            $('#provider_custom').slideDown();

        });
        $('#show-provider-list').on('click', function(e){
            e.preventDefault();
            $('#provider_name').val('');
            $('.show-provider-list').slideUp();
            $('.show-provider-custom').slideDown();
            $('#provider_list').slideDown();
            $('#provider_custom').slideUp();
        });

    };
    var datepicker = function() {
        $('.input-group.renewal-date').datepicker({
            format: "dd-mm-yyyy",
            autoclose: true,
            todayHighlight: true,
            startDate: '0d'
        }).on('changeDate', function(e){
            "use strict";
            var date = e.date;
            $('#renewal_day').val(date.getDate());
            $('#renewal_month').val(date.getMonth()+1);
            $('#renewal_year').val(date.getFullYear());
        });
    };
    var init = function() {
        $('.show-provider-list').hide();
        $('#provider_custom').hide();
        datepicker();
        bindEvents();
    };
    return {
        init: init
    }
})();

var reportMissing = (function() {
    var datepicker = function() {
        $('.input-group.missing-date').datepicker({
            format: "dd-mm-yyyy",
            autoclose: true,
            todayHighlight: true,
            endDate: '0d'
        }).on('changeDate', function(e){
            "use strict";
            var date = e.date;
            $('#missing-day').val(date.getDate());
            $('#missing-month').val(date.getMonth()+1);
            $('#missing-year').val(date.getFullYear());
        });
    };
    var init = function() {
        datepicker();
    };
    return {
        init: init
    }
})();

var racehorse = (function() {
    var datepicker = function() {
        $('.input-group.retired-date').datepicker({
            format: "dd-mm-yyyy",
            autoclose: true,
            todayHighlight: true,
            endDate: '0d'
        }).on('changeDate', function(e){
            "use strict";
            var date = e.date;
            $('#retired-day').val(date.getDate());
            $('#retired-month').val(date.getMonth()+1);
            $('#retired-year').val(date.getFullYear());
        });
    };
    var init = function() {
        datepicker();
        $('#no-retirement-date').on('click', function(e) {
            $('#retirement-date-section').slideUp();
            $('#show-retirement-date').parent().slideDown();

        });
        $('#show-retirement-date').on('click', function(e) {
            $('#retirement-date-section').slideDown();
            $('#show-retirement-date').parent().slideUp();
        });
    };
    return {
        init: init
    }
})();

var chipChecker = (function() {
    var bindEvents = function() {
        $('#chip-checker-form  input[name=type]').on('change', function(e) {
            $('#chipCheckerSubmitButton').removeAttr('disabled');
        });
        $('#chip-checker-form').submit(function(e){
            e.preventDefault();
            $('.chip-checker-wrapper').LoadingOverlay('show', {image : "",fontawesome : "fa fa-circle-notch fa-spin", fontawesomeColor : "#006760"});
            var type = $('#chip-checker-form  input[name=type]:checked').val();
            var search = $('#chip-checker-form  input[name=search]').val();
            $.ajax({
                url: '/dashboard/chip-checker',
                dataType: 'text',
                type: 'get',
                contentType: 'application/json',

                data: {'type': type, 'search' : search},
                success: function( data, textStatus, jQxhr ){
                    $('.chip-checker-wrapper').LoadingOverlay('hide');
                    $('#chipCheckerSubmitButton').html('<i class="fa fa-search"></i> Search');
                    $('#chipCheckerModal .modal-body').html(data);
                    $('#chipCheckerModal').modal('show');
                },
                error: function( jqXhr, textStatus, errorThrown ){
                    console.log( errorThrown );
                    $('.chip-checker-wrapper').LoadingOverlay('hide');
                    $('#chipCheckerSubmitButton').html('<i class="fa fa-search"></i> Search');
                }
            });
        });
    };
    var init = function() {
        $('#chipCheckerSubmitButton').attr('disabled', 'disabled');
        bindEvents();
    };
    return {
        init: init
    }
})();

var chipCheckerPopup = (function() {
    var bindEvents = function() {
        $('#chipCheckerModal').on('show.bs.modal', function () {
            $('#chip-checker-popup').submit(function(e){
                e.preventDefault();
                $('.chip-checker-wrapper').LoadingOverlay('show', {image : "",fontawesome : "fa fa-circle-notch fa-spin", fontawesomeColor : "#006760"});
                var type = $('#chip-checker-popup  input[name=type]:checked').val();
                var search = $('#chip-checker-popup  input[name=search]').val();
                $.ajax({
                    url: '/dashboard/chip-checker',
                    dataType: 'text',
                    type: 'get',
                    contentType: 'application/json',
                    data: {'type': type, 'search' : search},
                    success: function( data, textStatus, jQxhr ){
                        $('.chip-checker-wrapper').LoadingOverlay('hide');
                        $('#chipCheckerModal .modal-body').html(data);
                        $('#chipCheckerModal').modal('show');
                    },
                    error: function( jqXhr, textStatus, errorThrown ){
                        console.log( errorThrown );
                    }
                });
                $('.chip-checker-wrapper').LoadingOverlay('hide');
                $('#chipCheckerPopupSubmitButton').html('<i class="fa fa-search"></i> Search');
            });
        });
    };
    var init = function() {
        $('#chipCheckerPopupSubmitButton').attr('disabled', 'disabled');
        bindEvents();
    };
    return {
        init: init
    }
})();

var stripePayment = (function() {
    var setup = function() {
        // Create a Stripe client.
        var stripe = Stripe(stripePublicKey);

        // Create an instance of Elements.
        var elements = stripe.elements();

        // Custom styling can be passed to options when creating an Element.
        // (Note that this demo uses a wider set of styles than the guide below.)
        var style = {
            base: {
                color: '#32325d',
                lineHeight: '18px',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };

        // Create an instance of the card Element.
        var card = elements.create('card', {style: style, hidePostalCode: true});

        // Add an instance of the card Element into the `card-element` <div>.
        card.mount('#card-element');

        // Handle real-time validation errors from the card Element.
        card.addEventListener('change', function(event) {
            var displayError = document.getElementById('card-errors');
            if (event.error) {
                displayError.textContent = event.error.message;
            } else {
                displayError.textContent = '';
            }
        });

        // Handle form submission.
        var form = document.getElementById('payment-form');
        form.addEventListener('submit', function(event) {
            event.preventDefault();
            $('.overlay-loader-target').LoadingOverlay('show', {image : "",fontawesome : "fa fa-circle-notch fa-spin", fontawesomeColor : "#006760"});


            stripe.createToken(card, options).then(function(result) {
                if (result.error) {
                    // Inform the user if there was an error.
                    var errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                    $('.overlay-loader-target').LoadingOverlay('hide');
                } else {
                    // Send the token to your server.
                    stripeTokenHandler(result.token);
                }
            });
        });

        function stripeTokenHandler(token) {
            // Insert the token ID into the form so it gets submitted to the server
            var form = document.getElementById('payment-form');
            var hiddenInput = document.createElement('input');
            hiddenInput.setAttribute('type', 'hidden');
            hiddenInput.setAttribute('name', 'stripeToken');
            hiddenInput.setAttribute('value', token.id);
            form.appendChild(hiddenInput);

            // Submit the form
            form.submit();
        }

    };
    var init = function() {
        if ($('#payment-form').length) {
            setup();
        }
    };
    return {
        init: init
    }
})();

var changeOwnership = (function() {
    "use strict";
    var init = function() {
        $('.input-group.ownership-date').datepicker({
            format: "dd-mm-yyyy",
            autoclose: true,
            todayHighlight: true,
            endDate: '0d'
        }).on('changeDate', function(e){
            var date = e.date;
            $('#ownership-day').val(date.getDate());
            $('#ownership-month').val(date.getMonth()+1);
            $('#ownership-year').val(date.getFullYear());
        });
        $('.input-group.date-date').datepicker({
            format: "dd-mm-yyyy",
            autoclose: true,
            todayHighlight: true,
            endDate: '0d'
        }).on('changeDate', function(e){
            var date = e.date;
            $('#date-day').val(date.getDate());
            $('#date-month').val(date.getMonth()+1);
            $('#date-year').val(date.getFullYear());
        });
    };
    return {
        init: init
    }
})();

var dismissedComponents = (function() {
    "use strict";
    var init = function() {
        $('button[data-dismissed-component-id]').on('click', function(e) {
            var componentId = $(this).data('dismissed-component-id');
            console.log(componentId);
            $.ajax({
                type: "POST",
                url    : "/ajax/user/set-user-interface-dismissed-components",
                data   : {id: componentId},
                success: function( response ) {
                    //  Do we want to respond to the dismiss action?
                    console.log( response );
                }
            });
        });
    };
    return {
        init: init
    }
})();

var registerUpdate = (function() {
    "use strict";
    var init = function() {
        $('input[name=foodchain]').on('change', function(e) {
            if ($(this).val() == '71002') {
                $('.food-chain-warning').slideDown();
            } else {
                $('.food-chain-warning').slideUp();
            }
        });
    };
    return {
        init: init
    }
})();

var detailsIncorect = (function() {
    "use strict";
    var init = function() {
        var customPioButton = $('.pio-not-listed');
        var pioListButton = $('.pio-show-list');
        var customPioInput = $('.alt-pio');
        var pioList = $('.pio-select-list');
        customPioButton.on('click', function(e) {
            e.preventDefault();
            customPioInput.slideDown();
            $('#pio-list').prop('selectedIndex',0);
            pioList.slideUp();
        });
        pioListButton.on('click', function(e) {
            e.preventDefault();
            customPioInput.slideUp();
            $('#alt-pio').val('');
            pioList.slideDown();
        });
    };
    return {
        init: init
    }
})();

var ownershipStatus = (function() {
    "use strict";
    var bindEvents = function() {
        $('input[name=ownership_status]').on('click', function(e) {
            $('#submitOwnerStatus').removeAttr('disabled');
        });
    };

    var init = function() {
        $('#submitOwnerStatus').attr("disabled", "disabled");
        bindEvents();
    };

    return {
        init: init
    }
})();

var changeReason = (function() {
    "use strict";
    var bindEvents = function() {
        $('input[name=change_reason]').on('click', function(e) {
            $('#submitChangeReason').removeAttr('disabled');
        });
    };

    var init = function() {
        $('#submitChangeReason').attr("disabled", "disabled");
        bindEvents();
    };

    return {
        init: init
    }
})();

var poaVerifiedPoll = (function() {
    "use strict";
    var bindEvents = function() {
        $('#onfido-poa').each(pollServer);
    };
    var pollServer = function() {
        setTimeout(function() {
            $.ajax({
                url: "/ajax/user/onfido-poa-status",
                type: "GET",
                success: checkStatus,
                dataType: "json",
                complete: pollServer,
                timeout: 2000
            })
        }, 5000);
    };
    var checkStatus = function(data) {

        if (data.onfido_application_status_code == 'completed-failed') {
            location.href='/dashboard/verify/address-failed';
        }
        if (data.onfido_application_status_code == 'completed-deleted') {
            location.href='/dashboard/verify/address-success';
        }

    };
    var init = function() {
        bindEvents();
    };
    return {
        init: init
    }
})();

var universalSearch = (function() {
    var bindEvents = function() {
        $('#universal-checker-form  input[name=universal-search]').on('keyup', function(e) {
            if ($(this).val().length > 0) {
                $('#universalCheckerButton').removeAttr('disabled');
            }
        });
        $('#universalCheckerButton').on('click', function(e) {
            e.preventDefault();
            $('.universal-checker-wrapper').LoadingOverlay('show', {image : "",fontawesome : "fa fa-circle-notch fa-spin", fontawesomeColor : "#006760"});
            var search = $('#universal-checker-form  input[name=universal-search]').val();
            $('#pio-details-section').css('display', 'none');
            $.ajax({
                url: '/register/lookup/universal-search',
                type: 'get',
                contentType: 'application/json',
                dataType: "json",
                data: {'search' : search},
                success: function( data, textStatus, jQxhr ){
                    if(data.found == true) {
                        if(data.type != 'ueln') {
                            $('.universal-checker-wrapper').LoadingOverlay('hide');
                            $('#pio-details-section').css('display', 'block');
                            $('#input-search-type').val('passport');
                            $('#input-search-passport').val(search);
                        } else {
                            $('#input-search-ueln').val(search);
                            $('#input-search-type').val('ueln');
                            $('#universal-checker-form #search').trigger('click');
                        }
                    } else {
                        if(data.duplicate == true) {
                            location.href='/register/lookup/exists';
                        } else {
                            location.href='/register/lookup/not-found';
                        }
                    }
                },
                error: function( jqXhr, textStatus, errorThrown ){
                    $('.universal-checker-wrapper').LoadingOverlay('hide');
                    $('#universalCheckerButton').html('<i class="fa fa-search"></i> Search for equine');
                }
            });
        });
    };
    var init = function() {
        $('#universalCheckerButton').attr('disabled', 'disabled');
        bindEvents();
    };
    return {
        init: init
    }
})();

var pioCountryChange = (function() {
    var bindEvents = function() {
        $('#pioCountry').on('change', function(e) {
            var country = $(this).val();
            $('#addressLoader').LoadingOverlay('show', {image : "",fontawesome : "fa fa-circle-notch fa-spin", fontawesomeColor : "#006760"});
            $('#pio-list').empty();
            $.ajax({
                url: '/ajax/lookup/pio',
                type: 'get',
                contentType: 'application/json',
                dataType: "json",
                data: {'country' : country},
                success: function( data, textStatus, jQxhr ){
                    if (data.pios && data.count > 0) {
                        $('#pio-list').append($('<option>', {
                            value: '',
                            text: 'Select Pio'
                        }));
                        $.each(data.pios, function (index, value) {
                            $('#pio-list').append($('<option>', {
                                value: value,
                                text: value
                            }));
                        });
                        $('.pio-show-list').trigger('click');
                        $('#addressLoader').LoadingOverlay('hide');
                        $('.pio-show-list').show();
                    } else {
                        $('#addressLoader').LoadingOverlay('hide');
                        $('.pio-not-listed').trigger('click');
                        $('.pio-show-list').hide();
                    }
                }
            });
        });
    };
    var init = function() {
        bindEvents();
    };
    return {
        init: init
    }
})();

    function verificationList() {
        if ( ! $.fn.DataTable.isDataTable( '#allUsersTable' ) ) {
            var allUsersTable = $('#allUsersTable').dataTable({
                "searching": true,
                "processing": true,
                "serverSide": true,
                "ordering": false,
                "ajax": "/dashboard/list",
                "dom": "<'row'<'col-sm-6'f><'col-sm-6'l>>" +
                        "<'row'<'col-sm-12'tr>>" +
                        "<'row'<'col-sm-6'i><'col-sm-6'p>>",
                "language": {
                    "search": "_INPUT_",
                    "lengthMenu": '<span>Show:</span> _MENU_',
                    "searchPlaceholder": "Enter name or email",
                    "processing": '<i style="color: #006760;" class="fa fa-circle-notch fa-spin fa-4x"></i><span class="sr-only">Loading..n.</span> '
                },
                "initComplete": function(settings, json) {
                    $('#allUsersTable_filter input').unbind();
                    $('#allUsersTable_filter input').bind('keyup', function(e) {
                        if(e.keyCode === 13) {
                            allUsersTable.fnFilter(this.value);
                        }
                    });
                },
                "columns": [
                    { "data": "requested_date" },
                    { "data": "ueln" },
                    { "data": "equine_name" },
                    { "data": "owner_name" },
                    { "data": "vaccination_status" },
                    { "data": "action" }
                ]
            });
        }
    }



$(document).ready(function(){



    var uri = window.location.toString();
    if (uri.indexOf("?") > 0) {
        var clean_uri = uri.substring(0, uri.indexOf("?"));
        window.history.replaceState({}, document.title, clean_uri);
    }

    $('#autoMatchModal').modal('show');



    var imagePath = $('#equineImage').attr('src');

    $('#equineImage').croppie({
        enableExif: true,
        enforceBoundary: false,
        viewport: {
            width: 300,
            height: 357
        },
        boundary: {
            width: 300,
            height: 357
        },
        url: imagePath
    });
    $('#crop-image').on('click', function (ev) {
        $('#equineImage').croppie('result', {
            type: 'canvas',
            size: 'viewport',
            format: 'jpeg'
        }).then(function (resp) {
            $('#imagebase64').val(resp);
            $('#crop-image-form').submit();
        });
        return false;
    });

    $('#cedMatches').DataTable({
        "searching": false
    });

    $('.input-group.vaccination-date').datepicker({
        format: "dd/mm/yyyy",
        autoclose: true,
        todayHighlight: true,
        endDate: '0d'
    }).on('changeDate', function(e){
        "use strict";
        var code = $(this).data('vaccination-code');
        var date = e.date;
        $('#' + code + '-day').val(date.getDate());
        $('#' + code + '-month').val(date.getMonth()+1);
        $('#' + code + '-year').val(date.getFullYear());
        $('#submit').removeAttr('disabled');
    });

    $('.input-group.deceased-date').datepicker({
        format: "dd/mm/yyyy",
        autoclose: true,
        todayHighlight: true,
        endDate: '0d'
    }).on('changeDate', function(e){
        "use strict";
        var code = $(this).data('deceased-code');
        var date = e.date;
        $('#' + code + '-day').val(date.getDate());
        $('#' + code + '-month').val(date.getMonth()+1);
        $('#' + code + '-year').val(date.getFullYear());
        $('#submit').removeAttr('disabled');
    });

    $('.input-group.missing-date').datepicker({
        format: "dd/mm/yyyy",
        autoclose: true,
        todayHighlight: true,
        endDate: '0d'
    }).on('changeDate', function(e){
        "use strict";
        var code = $(this).data('missing-code');
        var date = e.date;
        $('#' + code + '-day').val(date.getDate());
        $('#' + code + '-month').val(date.getMonth()+1);
        $('#' + code + '-year').val(date.getFullYear());
        $('#submit').removeAttr('disabled');
    });

    $('.js-hidden').each(function(){
        "use strict";
        $(this).hide();
    });

    $('.input-group.sold-date').datepicker({
        format: "dd/mm/yyyy",
        autoclose: true,
        todayHighlight: true,
        endDate: '0d'
    }).on('changeDate', function(e){
        "use strict";
        var code = $(this).data('sold-code');
        var date = e.date;
        $('#' + code + '-day').val(date.getDate());
        $('#' + code + '-month').val(date.getMonth()+1);
        $('#' + code + '-year').val(date.getFullYear());
        $('#submit').removeAttr('disabled');
    });

    $('.js-hidden').each(function(){
        "use strict";
        $(this).hide();
    });

    $('#confirm').click(function() {
        if ($(this).is(':checked')) {
            $('#submit').removeAttr('disabled');
        } else {
            $('#submit').prop( "disabled", true );
        }
    });

    $('#search-type input[type=radio]').on('click', function(e) {
        "use strict";
        var type = $(this).val();
        $('.js-hidden').slideUp();
        $('#' + type + '-input').slideDown();
        $('#search').removeAttr('disabled');

        $('#' + type + '-input').find('input[type=text]').filter(':first').focus();

    });

    $('.form-submit-overlay').submit(function(e) {
        $('.stable-box').LoadingOverlay('show', {image : "", fontawesome : "fa fa-circle-notch fa-spin", fontawesomeColor : "#006760"});
    });

    $('.link-click-overlay').on('click', function(e) {
        $('.stable-box').LoadingOverlay('show', {image : "", fontawesome : "fa fa-circle-notch fa-spin", fontawesomeColor : "#006760"});
    });

    $('.open-menu').on('click', function() {
        $('.overlay').addClass('open');
        $('.open-menu').hide();
    });

    $('.close-menu').on('click', function() {
        $('.overlay').removeClass('open');
        $('.open-menu').show();
    });
    $('.open-manage-menu').on('click', function() {
        $('.manage-overlay').addClass('open');
    });

    $('.close-manage-menu').on('click', function() {
        $('.manage-overlay').removeClass('open');
        $('.open-manage-menu').show();
    });

    $('#death-date-unknown').on('click', function(e) {
        $('input[name=death-date-unknown]').val('true');
        $('#death-date-section').slideUp();
        $('#show-death-date').parent().slideDown();
    });
    $('#show-death-date').on('click', function(e) {
        $('input[name=death-date-unknown]').val('false');
        $('#death-date-section').slideDown();
        $('#show-death-date').parent().slideUp();
    });

    $(document).on('click', '.data-dismissed-component', function() {
        var componentId = $(this).data('dismissed-component-id');
        $.ajax({
            type: "POST",
            url    : "/ajax/user/set-user-interface-dismissed-components",
            data   : {id: componentId},
            success: function( response ) {

            }
        });
    });

    $(document).on('click', '#btnUpdateConfirm', function() {
        $('#updateConfirm').val('1');
        $('#myModal').modal('hide');
        $('#updateEquineDetailsForm').submit();
    });

    $(document).on('click', '.resetIdentity', function() {
        var userId = $(this).attr('rel');
        $('.stable-box-primary').LoadingOverlay('show', {image : "",fontawesome : "fa fa-circle-notch fa-spin", fontawesomeColor : "#006760"});
        $.ajax({
            type: "POST",
            url    : "/admin/users/reset-identity",
            data   : {userid: userId},
            dataType: "json",
            success: function( response ) {
                $('.stable-box-primary').LoadingOverlay('hide');
                $('#failedUsersTable').dataTable().fnDraw();
                if(response.success) {
                    $('#modalSuccessMessage').html(response.message);
                    $('#successModal').modal('show');
                } else {
                    $('#modalErrorMessage').html(response.message);
                    $('#errorModal').modal('show');
                }
            }
        });
    });

    $(document).on('click', '.passIdentity', function() {
        var userId = $(this).attr('rel');
        $('.stable-box-primary').LoadingOverlay('show', {image : "",fontawesome : "fa fa-circle-notch fa-spin", fontawesomeColor : "#006760"});
        $.ajax({
            type: "POST",
            url    : "/admin/users/pass-identity",
            data   : {userid: userId},
            success: function( response ) {
                $('.stable-box-primary').LoadingOverlay('hide');
                $('#failedUsersTable').dataTable().fnDraw();
                if(response.success) {
                    $('#modalSuccessMessage').html(response.message);
                    $('#successModal').modal('show');
                } else {
                    $('#modalErrorMessage').html(response.message);
                    $('#errorModal').modal('show');
                }
            }
        });
    });

    $(document).on('click', '.resendPostcard', function() {
        var userId = $(this).attr('rel');
        $('.stable-box-primary').LoadingOverlay('show', {image : "",fontawesome : "fa fa-circle-notch fa-spin", fontawesomeColor : "#006760"});
        $.ajax({
            type: "POST",
            url    : "/admin/users/resend-postcard",
            data   : {userid: userId},
            success: function( response ) {
                $('.stable-box-primary').LoadingOverlay('hide');
                if(response.success) {
                    $('#modalSuccessMessage').html(response.message);
                    $('#successModal').modal('show');
                } else {
                    $('#modalErrorMessage').html(response.message);
                    $('#errorModal').modal('show');
                }
            }
        });
    });

    $(document).on('click', '.resetOnlineCheck', function() {
        var userId = $(this).attr('rel');
        $('.stable-box-primary').LoadingOverlay('show', {image : "",fontawesome : "fa fa-circle-notch fa-spin", fontawesomeColor : "#006760"});
        $.ajax({
            type: "POST",
            url    : "/admin/users/reset-online",
            data   : {userid: userId},
            success: function( response ) {
                $('.stable-box-primary').LoadingOverlay('hide');
                if(response.success) {
                    $('#modalSuccessMessage').html(response.message);
                    $('#successModal').modal('show');
                } else {
                    $('#modalErrorMessage').html(response.message);
                    $('#errorModal').modal('show');
                }
            }
        });
    });

    $('#usersTabs a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        var target = $(e.target).attr("href");
        switch(target) {
            case '#verificationList':
                verificationList();
                break;
            case '#failedUsers':
                failedUsers();
                break;
            case '#reviewUsers':
                reviewUsers();
                break;
            case '#postcardUsers':
                postcardUsers();
                break;
            case '#previousNameUsers':
                previousNameUsers();
                break;
        }

    });

    verificationList();
    $(document).on('click', 'a.location-toggle', function() {
	$(this).find('[class*="angle"]').toggleClass('fa-angle-down fa-angle-right');
    });

    $('.collapse').on('show.bs.collapse', function (e) {
        // Get clicked element that initiated the collapse...
    });

    $(document).on('click', '.hovereffect', function() {
        $('#imageModal').modal('show');
    });

    $(document).on('click', '#documentImage', function() {
            var angle = ($('#documentImage').data('angle') + 90) || 90;
            $('#documentImage').css({'transform': 'rotate(' + angle + 'deg)'});
            $('#documentImage').data('angle', angle);
    });
});



$(document).ready(function() {
    dashboard.init();
    addressLookup.init();
    showHide.init();
    addMicrochip.init();
    passwordBox.init();
    accountTabs.init();
    passwordStrength.init();
    submitButtonAnimation.init();
    formConfirm.init();
    modalControl.init();
    emailVerifiedPoll.init();
    identityVerifiedPoll.init();
    locationType.init();
    vaccinations.init();
    insurance.init();
    reportMissing.init();
    racehorse.init();
    registration.init();
    stripePayment.init();
    changeOwnership.init();
    registerUpdate.init();
    detailsIncorect.init();
    chipChecker.init();
    chipCheckerPopup.init();
    dismissedComponents.init();
    ownershipStatus.init();
    poaVerifiedPoll.init();
    changeReason.init();
    universalSearch.init();
    pioCountryChange.init();
    locationChange.init();
    saveLocation.init();
    btnLocationSubmit.init();
});
